<template>
  <v-slide-group show-arrows>
    <v-dialog
      fullscreen
      v-model="viewImgDialog"
      style="height: 100vh; background: rgba(215, 215, 215, 0.1);
  backdrop-filter: blur(20px);"
    >
      <div
        class="expande-horizontal centraliza"
        style="height: 100vh; background: rgba(215, 215, 215, 0.1);
  backdrop-filter: blur(20px);"
      >
        <v-flex xs12 md4>
          <div
            class="expande-horizontal centraliza pa-6"
            style="background: #e2e2e2; flex-direction: column; border-radius: 6px; "
          >
            <div class="expande-horizontal">
              <v-btn
                :color="$theme.primary"
                dark
                class="mb-3"
                @click="setModalViewImg('', false)"
              >
                <v-icon>mdi-close</v-icon>
                <span class="fonte">fechar</span>
              </v-btn>
              <v-spacer></v-spacer>
            </div>
            <img
              :style="`max-height: 70vh; border-radius: 6px;`"
              class=""
              :src="link"
            />
          </div>
        </v-flex>
      </div>
    </v-dialog>
    <v-slide-item v-for="(link, i) in getLinks" :key="link">
      <v-card
        color="#333"
        width="140"
        height="180"
        class="pa-2 pl-0 ml-0 pl-2 ma-3"
      >
        <v-img
          @click="setModalViewImg(link, true)"
          style="border-radius: 6px; height: 140px; width: 400px; cursor: pointer;"
          :src="link"
        ></v-img>
        <v-btn
          @click="
            createConfirmAction({
              opened: true,
              message: 'Remover Imagem?',
              action: removeLink,
              isScoped: true,
              action_value: link
            })
          "
          text
          block
          class="fonte"
        >
          <span class="fonteMini text-capitalize grey--text">remover</span>
          <v-icon color="grey" size="12">mdi-image-remove</v-icon>
        </v-btn>
      </v-card>
    </v-slide-item>
    <v-slide-item>
      <v-card
        v-if="showOnCondition"
        color="#333"
        width="140"
        height="180"
        class="pa-2 pl-0 ml-0 pl-2 ma-3"
      >
        <div
          class="input-wrapper"
          style="display: flex; height: 140px; align-items: center;"
        >
          <label for="input-file">
            <div
              class="expande-horizontal centraliza"
              style="cursor: pointer; width: 67px; height: 80px;"
            >
              <v-icon size="30" v-if="initialized" color="#333"
                >mdi-cloud-upload-outline</v-icon
              >
              <v-icon size="50" v-else color="grey">mdi-image-plus</v-icon>
              <v-progress-circular
                v-if="initialized"
                :value="loading"
                size="50"
                style="margin-left: -40px;"
                :indeterminate="initialized && !loading"
                :color="$theme.primary"
              ></v-progress-circular>
            </div>
          </label>
          <input
            style="cursor: pointer;"
            id="input-file"
            type="file"
            ref="input"
            accept="image/*"
            name="importxml"
            @change="upload"
          />
          <span id="file-name"></span>
        </div>
      </v-card>
    </v-slide-item>
  </v-slide-group>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject
} from "firebase/storage";
export default {
  props: ["unique", "uploaded"],
  data() {
    return {
      viewImgDialog: false,
      link: "",
      loading: 0,
      initialized: false
    };
  },
  computed: {
    ...mapGetters([
      "getModalSendArchive",
      "getLinks",
      "getPercentage",
      "getLoggedUser"
    ]),
    showOnCondition() {
      let res = false;
      if (this.getLinks.length > 0 && this.unique) {
        res = false;
      }

      if (!this.getLinks.length) {
        res = true;
      }

      if (this.getLinks.length && !this.unique) {
        res = true;
      }

      return res;
    }
  },
  methods: {
    ...mapActions([
      "createConfirmAction",
      "addLink",
      "setPencentageSent",
      "disableModalSendArchive",
      "enableLoading",
      "disableLoading",
      "setCurrentLinkInfo"
    ]),
    setModalViewImg(link, valueModal) {
      if (this.valueModal) {
        this.link = link;
        this.viewImgDialog = valueModal;
      } else {
        this.viewImgDialog = valueModal;
        setTimeout(() => {
          this.link = link;
        }, 300);
      }
    },
    resetInput() {
      this.initialized = false;
      this.loading = 0;
      this.$refs.input.value = "";
    },
    removeLink(link) {
      console.log(link);
      const self = this;
      const storage = getStorage();
      const httpsReference = ref(storage, link);
      deleteObject(httpsReference).then(function() {
        self.$store.commit(
          "setLinks",
          self.$store.getters.getLinks.filter(l => l !== link)
        );
        self.$store.dispatch("createGlobalMessage", {
          type: "success",
          message: "Imagem removida com sucesso!",
          timeout: 3000
        });
        self.$emit("input");
      });
    },
    upload(evt) {
      if (this.initialized) {
        this.resetInput();
        return;
      }
      this.initialized = true;
      evt.stopPropagation();
      evt.preventDefault();
      var file = evt.target.files[0];
      if (!file) return;

      var metadata = {
        contentType: file.type
      };

      this.setCurrentLinkInfo({ file, metadata });
      var metadata = {
        contentType: file.type
      };

      const self = this;

      const storage = getStorage();
      const storageRef = ref(storage);
      const imagesRef = ref(
        storageRef,
        `images/${self.getLoggedUser_id}-img/${file.name}`
      );
      const taskLoad = uploadBytesResumable(imagesRef, file, metadata);
      taskLoad.on(
        "state_changed",
        //get progress
        snapshot => {
          self.loading =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {},
        //success
        () => {
          getDownloadURL(taskLoad.snapshot.ref).then(link => {
            self.addLink(link);
            self.loading = 0;
            setTimeout(() => {
              self.initialized = false;
              self.resetInput();
              self.$emit("input");
            }, 1000);
          });
        }
      );
    }
  }
};
</script>

<style scoped>
input[type="file"] {
  display: none;
}

.input-wrapper label {
  background: linear-gradient(-45deg, #eaeaea, #dfdfe0);
  border-radius: 5px;
  color: #333;
  margin: 8px;
  padding: 6px 20px;
}

.btn2 {
  background: linea r-gradient(-45deg, #4578d8, #3d5aff);
}

.input-wrapper label:hover {
  background-color: #2980b9;
}

.img {
  border-radius: 6px;
}
</style>
